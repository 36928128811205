/* styles.css */

body {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: #000000 !important;
  color: white;
}
.container-fluid {
  padding-top: 50px;
}
.custom-container {
  border: none;
  padding: 5px;
  text-align: center;
  margin: 10px;
  height: 550px;
  background: #1a1a1a; /* Dark background color */
  background: linear-gradient(to bottom, #1a1a1a, #333); /* Dark gradient */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* Shadow effect */
  transition: box-shadow 0.3s ease; /* Smooth transition for the shadow effect */
  position: relative;
  overflow: hidden;
}

.custom-container:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5); /* Enhanced shadow on hover */
}

.page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  background: #1a1a1a;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.page.inactive {
  opacity: 0;
  transform: translateX(-100%);
}

.scroll{
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.cnt-1 {
  padding: 10px !important;
}
.logo-container img{
  width: 40%;
  height: auto;
}

.connectBtn {
  padding: 15px 30px;
  margin: 10% auto 10% auto;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819  51%, #FF512F  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.connectBtn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.connectBtn:active {
  transform: scale(0.95);
}

.installBtn, .rfrshBtn, .initiateBtn{
  appearance: none;
  backface-visibility: hidden;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(39, 174, 96, .15) 0 4px 9px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 13px 20px;
  width: 50%;
  margin-bottom: 5%;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}
.installBtn {
  background-color: #27ae60;
}

.installBtn:hover {
  background-color: #1e8449;
  opacity: 1;
  transform: translateY(0);
  transition-duration: .35s;
  box-shadow: rgba(39, 174, 96, .2) 0 6px 12px;
}

.installBtn:active {
  transform: translateY(2px);
  transition-duration: .35s;
}

.rfrshBtn {
  background-color: #37AEE2;
}

.rfrshBtn:hover {
  background-color: #17759C;
  opacity: 1;
  transform: translateY(0);
  transition-duration: .35s;
  box-shadow: rgba(39, 136, 174, 0.2) 0 6px 12px;
}

.initiateBtn {
  background-color: #36A9AE;
}

.initiateBtn:hover {
  background-color: #2a8589;
  opacity: 1;
  transform: translateY(0);
  transition-duration: .35s;
  box-shadow: rgba(39, 163, 174, 0.2) 0 6px 12px;
}

.initiateBtn:active {
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 4px inset, rgba(0, 0, 0, 0.4) 0 1px 1px;
}

.errorBox {
  color: #fff;
  background-color: rgba(255, 92, 92, 0.8); /* Red background color with slight opacity */
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
  display: none; /* Hide the error box initially */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle lift effect */
}


.strt, .stp, .wtd, .dtls {
  appearance: none;
  backface-visibility: hidden;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(39, 174, 96, .15) 0 4px 9px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 13px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  margin-top: .75rem;
}
.strt:hover, .stp:hover, .wtd:hover, .dtls:hover {
  opacity: 1;
  transform: translateY(0);
  transition-duration: .35s;
}

.strt {
  width: 40%;
  background-color: #27ae60;
  margin-right: 5%;
}
.strt:hover {
  background-color: #1e8449;
}
.stp {
  width: 40%;
  background-color: #ff3333;
}
.stp:hover {
  background-color: #d22d2d;
}
.wtd {
  width: 65%;
  background-color: #ff9900;
}
#wtdNum {
  width: 14%;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border: 2px 2px 2px 2px solid gray;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-top: .75rem;
  height: 50px;
}
::placeholder {
  opacity: 0.5;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.wtd:hover {
  background-color: #e68019;
}
.dtls {
  width: 81%;
  background-color: #0db9f2;
}
.dtls:hover {
  background-color: #0099cc;
}

.range-slider {
  width: 100%;
  margin: 0;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
#range {
  -webkit-appearance: none;
  width: 100%;
}
#range:focus {
  outline: none;
}
#range::before,
#range::after {
  position: absolute;
  top: 2rem;
  color: #333;
  font-size: 14px;
  line-height: 1;
  padding: 3px 5px;
  background-color: #8145a2;
  border-radius: 4px;
}
#range::before {
  left: 0;
  content: attr(data-min);
}
#range::after {
  right: 0;
  content: attr(data-max);
}
#range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  cursor: pointer;
  animate: 0.2s;
  background: linear-gradient(90deg, #F09819 var(--range-progress), #dee4ec var(--range-progress));
  border-radius: 1rem;
}
#range::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 0.25rem solid #F09819;
  box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  height: 22px;
  width: 22px;
  margin-top: -5px;
  transform: translateY(calc(-50% + 8px));
}
.range-slider {
  position: relative;
  width: 100%;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  background: #ccc; /* Default track background */
  border-radius: 5px;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: orange;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background-color: orange;
  border-radius: 50%;
  cursor: pointer;
}

/* Fill the track before the slider thumb */
input[type="range"]::-webkit-slider-runnable-track {
  height: 8px;
  background: linear-gradient(to right, orange var(--range-progress), #ccc var(--range-progress));
  border-radius: 5px;
}

input[type="range"]::-moz-range-progress {
  background-color: orange;
  height: 8px;
}

input[type="range"]::-moz-range-track {
  background-color: #ccc;
  height: 8px;
}

#tooltip {
  position: absolute;
  top: -25px;
  transform: translateX(-50%);
  color: white;
  background-color: orange;
  padding: 1px 4px 1px 4px;
  border-radius: 5px;
}

.slide {
  width: 80%;
  margin-left: 10%;
}
.live{
  display: none;
}
.live h4{
  font-size: 1.2rem !important;
}
.rsk{
  font-size: 1.3rem !important;
}
/* Show the error box when it has content */
.errorBox:empty {
  display: none;
}

.reload-icon {
  transition: transform 0.2s ease;
  margin-bottom: 3px;
}

.reload-icon:active {
  transform: rotate(30deg);
}


.errorBox:not(:empty) {
  display: block;
}

#page4 h4 {
  margin-top: 14px;
}

.output {
  width: 100%;
  height: auto;
}
.h-line{
  width: 80%;
  border: 1px solid #fff;
}
.line {
  margin: 5px 0;
}
.from {
  font-size: 12px;
  font-weight: bold;
}
.to {
  font-size: 12px;
  font-weight: bold;
}
.pending {
  background-color: #ffc248;
  padding: 2px 5px;
  border-radius: 5px;
  display: inline-block;
  font-size: 11px;
  margin-bottom: 10px;
}
.complete {
  background-color: #9ee68d;
  padding: 2px 5px;
  border-radius: 5px;
  display: inline-block;
  font-size: 11px;
  margin-bottom: 10px;
}
.denied {
  background-color: #fdb2be;
  padding: 2px 5px;
  border-radius: 5px;
  display: inline-block;
  font-size: 11px;
  margin-bottom: 10px;
}
.amount {
  font-size: 12px;
}
.addr1 {
  font-size: 12px;
}
.addr2 {
  font-size: 12px;
}

.text-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-box h1 {
  background: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin: 0;
}
#prft {
  background: linear-gradient(50deg, rgba(165,47,255,1) 2%, rgba(25,123,240,1) 50%, rgba(140,35,238,1) 88%);;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.cnt-3 h2{
  margin-top: 1.5rem;
}
.rsk{
  background: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  color: white;
  text-align: center;
  padding: 10px;
}
.footer a, .footer span {
  margin-right: 5px; /* Adjust this margin as needed */
  display: inline-flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.4);
  float: right; /* Vertical alignment adjustment */
}

.footer span:hover {
  color: rgb(255, 255, 255);
}

canvas {
  background-image: radial-gradient(circle at top right, #331a00, transparent), radial-gradient(circle at 20% 80%, #663300, transparent);
 
  position: fixed;
  width: 100%;
  height: 100%;
}
.w-100{
  width: 100%;
}

/* Media query for small screens (up to 767px width) */
@media (max-width: 767px) {
  .container-fluid {
    padding-top: 0px;
  }
  .custom-container {
    margin: 5px;
  }

  .cnt-1 {
    height: 170px;
  }

  .cnt-2 {
    height: 450px;
  }

  .cnt-3 {
    height: 150px;
  }
}

/* Media query for iPad and Surface Pro-like devices (768px to 1023px width) */
@media (min-width: 768px) and (max-width: 1023px) {
  .custom-container {
    height: 500px;
    font-size: 80%;
    margin: 0;
  }
}

/* Bootstrap Carousel styles */

